import { Component } from '@angular/core';
import { NavigationEnd, NavigationError, NavigationStart, Router, RouterOutlet } from '@angular/router';
import { IconsService } from './core/icons/icons.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    standalone: true,
    imports: [RouterOutlet],
})
export class AppComponent {
    /**
     * Constructor
     */
    constructor(
        private iconsService: IconsService,
        private router: Router,
    ) {
        this.router.events.subscribe((event) => {
            if (event instanceof NavigationStart) {
                console.log('NavigationStart:', event.url);
            }
            if (event instanceof NavigationEnd) {
                console.log('NavigationEnd:', event.url);
            }
            if (event instanceof NavigationError) {
                console.error('NavigationError:', event.error);
            }
        });
       this.iconsService.initIcons();
    }
}
