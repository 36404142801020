/* eslint-disable */
import { FuseNavigationItem } from '@fuse/components/navigation';

export const defaultNavigation: FuseNavigationItem[] = [
    {
        id: 'dashboard',
        title: 'home',
        type: 'basic',
        icon: 'solar_home2Outline',
        classes: {
            icon: 'icon-size-5',
        },
        link: '/dashboard',
    },
    {
        id: 'crm',
        title: 'CRM',
        type: 'group',
        icon: 'solar_layersMinimalisticOutline',
        children: [
            {
                id: 'contacts',
                title: 'Contacts',
                type: 'basic',
                icon: 'solar_userOutline',
                classes: {
                    icon: 'icon-size-5 mr-3',
                },
                link: '/crm/contacts',
            },
            {
                id: 'accounts',
                title: 'Accounts',
                type: 'basic',
                icon: 'solar_plateOutline',
                classes: {
                    icon: 'icon-size-5 mr-3',
                },
                link: '/crm/accounts',
            },
            {
                id: 'opportunities',
                title: 'Opportunities',
                type: 'basic',
                icon: 'solar_alignLeftOutline',
                classes: {
                    icon: 'icon-size-5 mr-3',
                },
                link: '/crm/opportunities',
            },
            {
                id: 'tasks',
                title: 'Tasks',
                type: 'basic',
                icon: 'solar_sortByTimeOutline',
                classes: {
                    icon: 'icon-size-5 mr-3',
                },
                link: '/crm/tasks',
            },
        ],
    },
    {
        id: 'manage',
        title: 'Manage',
        type: 'group',
        icon: 'solar_settingsOutline',
        children: [
            {
                id: 'manageUsers',
                title: 'Users',
                type: 'basic',
                icon: 'solar_usersGroupRoundedOutline',
                classes: {
                    icon: 'icon-size-5 mr-3',
                },
                link: '/manage/users',
            },
            {
                id: 'settings',
                title: 'Settings',
                type: 'basic',
                icon: 'solar_settingsMinimalisticOutline',
                classes: {
                    icon: 'icon-size-5 mr-3',
                },
                link: '/manage/settings',
            },
        ],
    },
];
export const compactNavigation: FuseNavigationItem[] = [
    {
        id: 'dashboard',
        title: 'Home',
        type: 'basic',
        icon: 'solar_home2Outline',
        classes: {
            icon: 'icon-size-5',
        },
        link: '/dashboard',
    },
    {
        id: 'crm',
        title: 'CRM',
        type: 'aside',
        icon: 'solar_layersMinimalisticOutline',
        classes: {
            icon: 'icon-size-5',
        },
        children: [], // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
    },
    {
        id: 'manage',
        title: 'Manage',
        type: 'aside',
        icon: 'solar_settingsOutline',
        classes: {
            icon: 'icon-size-5',
        },
        children: [], // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
    },
];
export const futuristicNavigation: FuseNavigationItem[] = [
    {
        id: 'dashboards',
        title: 'DASHBOARDS',
        type: 'group',
        children: [], // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
    },
    {
        id: 'apps',
        title: 'APPS',
        type: 'group',
        children: [], // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
    },
    {
        id: 'others',
        title: 'OTHERS',
        type: 'group',
    },
    {
        id: 'pages',
        title: 'Pages',
        type: 'aside',
        icon: 'heroicons_outline:document-duplicate',
        children: [], // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
    },
    {
        id: 'user-interface',
        title: 'User Interface',
        type: 'aside',
        icon: 'heroicons_outline:rectangle-stack',
        children: [], // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
    },
    {
        id: 'navigation-features',
        title: 'Navigation Features',
        type: 'aside',
        icon: 'heroicons_outline:bars-3',
        children: [], // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
    },
];
export const horizontalNavigation: FuseNavigationItem[] = [
    {
        id: 'dashboards',
        title: 'Dashboards',
        type: 'group',
        icon: 'heroicons_outline:home',
        children: [], // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
    },
    {
        id: 'apps',
        title: 'Apps',
        type: 'group',
        icon: 'heroicons_outline:squares-2x2',
        children: [], // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
    },
    {
        id: 'pages',
        title: 'Pages',
        type: 'group',
        icon: 'heroicons_outline:document-duplicate',
        children: [], // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
    },
    {
        id: 'user-interface',
        title: 'UI',
        type: 'group',
        icon: 'heroicons_outline:rectangle-stack',
        children: [], // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
    },
    {
        id: 'navigation-features',
        title: 'Misc',
        type: 'group',
        icon: 'heroicons_outline:bars-3',
        children: [], // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
    },
];
