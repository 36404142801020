import { inject, Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import {
  solar_alignLeftOutline,
  solar_bellOutline,
  solar_boardOutline,
  solar_buildingsOutline,
  solar_calendarDateOutline,
  solar_calendarMinimalisticOutline,
  solar_calendarOutline,
  solar_caseRoundMinimalisticOutline,
  solar_layersMinimalisticOutline,
  solar_letterOutline,
  solar_linkMinimalistic2Outline,
  solar_listOutline,
  solar_listMinimalisticOutline,
  solar_lockKeyholeMinimalisticOutline,
  solar_lockKeyholeMinimalisticUnlockedOutline,
  solar_lockPasswordOutline,
  solar_lockPasswordUnlockedOutline,
  solar_magnifierOutline,
  solar_mapPointOutline,
  solar_menuDotsBold,
  solar_penNewSquareOutline,
  solar_phoneOutline,
  solar_plateOutline,
  solar_receiveSquareOutline,
  solar_sendSquareOutline,
  solar_settingsMinimalisticOutline,
  solar_settingsOutline,
  solar_shareOutline,
  solar_shieldUserOutline,
  solar_sortByTimeOutline,
  solar_sortOutline,
  solar_textSquareOutline,
  solar_trashBin2Outline,
  solar_trashBinTrashOutline,
  solar_tuning2Outline,
  solar_userCheckOutline,
  solar_userOutline,
  solar_usersGroupRoundedOutline,
  solar_tableOutline,
  solar_usersGroupTwoRoundedOutline,
  solar_widgetAddOutline,
  solar_searchOutline,
  solar_smartphoneOutline,
  solar_refreshOutline,
  solar_dollarOutline,
  solar_globalOutline,
  solar_hashtagSquareOutline,
  solar_fileTextOutline,
  solar_checklistMinimalisticOutline,
  solar_checkSquareOutline,
  solar_listCheckMinimalisticOutline,
  solar_sortHorizontalOutline,
  solar_verifiedCheckOutline,
  solar_closeSquareOutline,
  solar_infoCircleOutline,
  solar_home2Outline, solar_altArrowRightOutline, solar_addCircleOutline,
} from '../../../../public/icons/solar-outline';
import { solar_checkCircleBold } from '../../../../public/icons/solar-bold';

@Injectable({ providedIn: 'root' })
export class IconsService {
    /**
     * Constructor
     */
    constructor(
        public iconRegistry: MatIconRegistry,
        public sanitizer: DomSanitizer,
    ) {
        const domSanitizer = inject(DomSanitizer);
        const matIconRegistry = inject(MatIconRegistry);

        // Register icon sets
        matIconRegistry.addSvgIconSet(
            domSanitizer.bypassSecurityTrustResourceUrl(
                'icons/material-twotone.svg'
            )
        );
        matIconRegistry.addSvgIconSetInNamespace(
            'mat_outline',
            domSanitizer.bypassSecurityTrustResourceUrl(
                'icons/material-outline.svg'
            )
        );
        matIconRegistry.addSvgIconSetInNamespace(
            'mat_solid',
            domSanitizer.bypassSecurityTrustResourceUrl(
                'icons/material-solid.svg'
            )
        );
        matIconRegistry.addSvgIconSetInNamespace(
            'feather',
            domSanitizer.bypassSecurityTrustResourceUrl('icons/feather.svg')
        );
        matIconRegistry.addSvgIconSetInNamespace(
            'heroicons_outline',
            domSanitizer.bypassSecurityTrustResourceUrl(
                'icons/heroicons-outline.svg'
            )
        );
        matIconRegistry.addSvgIconSetInNamespace(
            'heroicons_solid',
            domSanitizer.bypassSecurityTrustResourceUrl(
                'icons/heroicons-solid.svg'
            )
        );
        matIconRegistry.addSvgIconSetInNamespace(
            'heroicons_mini',
            domSanitizer.bypassSecurityTrustResourceUrl(
                'icons/heroicons-mini.svg'
            )
        );
    }

    initIcons(): void {
        this.iconRegistry.addSvgIconLiteral(
            'solar_lockKeyholeMinimalisticOutline',
            this.sanitizer.bypassSecurityTrustHtml(solar_lockKeyholeMinimalisticOutline),
        );
        this.iconRegistry.addSvgIconLiteral(
            'solar_lockKeyholeMinimalisticUnlockedOutline',
            this.sanitizer.bypassSecurityTrustHtml(solar_lockKeyholeMinimalisticUnlockedOutline),
        );
        this.iconRegistry.addSvgIconLiteral(
            'solar_lockPasswordOutline',
            this.sanitizer.bypassSecurityTrustHtml(solar_lockPasswordOutline),
        );
        this.iconRegistry.addSvgIconLiteral(
            'solar_lockPasswordUnlockedOutline',
            this.sanitizer.bypassSecurityTrustHtml(solar_lockPasswordUnlockedOutline),
        );
        this.iconRegistry.addSvgIconLiteral(
            'solar_caseRoundMinimalisticOutline',
            this.sanitizer.bypassSecurityTrustHtml(solar_caseRoundMinimalisticOutline),
        );
        this.iconRegistry.addSvgIconLiteral(
            'solar_usersGroupRoundedOutline',
            this.sanitizer.bypassSecurityTrustHtml(solar_usersGroupRoundedOutline),
        );
        this.iconRegistry.addSvgIconLiteral(
            'solar_shieldUserOutline',
            this.sanitizer.bypassSecurityTrustHtml(solar_shieldUserOutline),
        );
        this.iconRegistry.addSvgIconLiteral(
            'solar_magnifierOutline',
            this.sanitizer.bypassSecurityTrustHtml(solar_magnifierOutline),
        );
        this.iconRegistry.addSvgIconLiteral(
            'solar_sortOutline',
            this.sanitizer.bypassSecurityTrustHtml(solar_sortOutline),
        );
        this.iconRegistry.addSvgIconLiteral(
            'solar_settingsOutline',
            this.sanitizer.bypassSecurityTrustHtml(solar_settingsOutline),
        );
        this.iconRegistry.addSvgIconLiteral(
            'solar_userCheckOutline',
            this.sanitizer.bypassSecurityTrustHtml(solar_userCheckOutline),
        );
        this.iconRegistry.addSvgIconLiteral(
            'solar_tuning2Outline',
            this.sanitizer.bypassSecurityTrustHtml(solar_tuning2Outline),
        );
        this.iconRegistry.addSvgIconLiteral(
            'solar_usersGroupTwoRoundedOutline',
            this.sanitizer.bypassSecurityTrustHtml(solar_usersGroupTwoRoundedOutline),
        );
        this.iconRegistry.addSvgIconLiteral(
            'solar_bellOutline',
            this.sanitizer.bypassSecurityTrustHtml(solar_bellOutline),
        );
        this.iconRegistry.addSvgIconLiteral(
            'solar_receiveSquareOutline',
            this.sanitizer.bypassSecurityTrustHtml(solar_receiveSquareOutline),
        );
        this.iconRegistry.addSvgIconLiteral(
            'solar_sendSquareOutline',
            this.sanitizer.bypassSecurityTrustHtml(solar_sendSquareOutline),
        );
        this.iconRegistry.addSvgIconLiteral(
            'solar_settingsMinimalisticOutline',
            this.sanitizer.bypassSecurityTrustHtml(solar_settingsMinimalisticOutline),
        );
        this.iconRegistry.addSvgIconLiteral(
            'solar_checkCircleBold',
            this.sanitizer.bypassSecurityTrustHtml(solar_checkCircleBold),
        );
        this.iconRegistry.addSvgIconLiteral(
            'solar_userOutline',
            this.sanitizer.bypassSecurityTrustHtml(solar_userOutline),
        );
        this.iconRegistry.addSvgIconLiteral(
            'solar_trashBinTrashOutline',
            this.sanitizer.bypassSecurityTrustHtml(solar_trashBinTrashOutline),
        );
        this.iconRegistry.addSvgIconLiteral(
            'solar_listOutline',
            this.sanitizer.bypassSecurityTrustHtml(solar_listOutline),
        );
        this.iconRegistry.addSvgIconLiteral(
            'solar_trashBin2Outline',
            this.sanitizer.bypassSecurityTrustHtml(solar_trashBin2Outline),
        );
        this.iconRegistry.addSvgIconLiteral(
            'solar_calendarDateOutline',
            this.sanitizer.bypassSecurityTrustHtml(solar_calendarDateOutline),
        );
        this.iconRegistry.addSvgIconLiteral(
            'solar_calendarOutline',
            this.sanitizer.bypassSecurityTrustHtml(solar_calendarOutline),
        );
        this.iconRegistry.addSvgIconLiteral(
            'solar_calendarMinimalisticOutline',
            this.sanitizer.bypassSecurityTrustHtml(solar_calendarMinimalisticOutline),
        );
        this.iconRegistry.addSvgIconLiteral(
            'solar_textSquareOutline',
            this.sanitizer.bypassSecurityTrustHtml(solar_textSquareOutline),
        );
        this.iconRegistry.addSvgIconLiteral(
            'solar_shareOutline',
            this.sanitizer.bypassSecurityTrustHtml(solar_shareOutline),
        );
        this.iconRegistry.addSvgIconLiteral(
            'solar_penNewSquareOutline',
            this.sanitizer.bypassSecurityTrustHtml(solar_penNewSquareOutline),
        );
        this.iconRegistry.addSvgIconLiteral(
            'solar_letterOutline',
            this.sanitizer.bypassSecurityTrustHtml(solar_letterOutline),
        );
        this.iconRegistry.addSvgIconLiteral(
            'solar_mapPointOutline',
            this.sanitizer.bypassSecurityTrustHtml(solar_mapPointOutline),
        );
        this.iconRegistry.addSvgIconLiteral(
            'solar_phoneOutline',
            this.sanitizer.bypassSecurityTrustHtml(solar_phoneOutline),
        );
        this.iconRegistry.addSvgIconLiteral(
            'solar_buildingsOutline',
            this.sanitizer.bypassSecurityTrustHtml(solar_buildingsOutline),
        );
        this.iconRegistry.addSvgIconLiteral(
            'solar_menuDotsBold',
            this.sanitizer.bypassSecurityTrustHtml(solar_menuDotsBold),
        );
        this.iconRegistry.addSvgIconLiteral(
            'solar_sortByTimeOutline',
            this.sanitizer.bypassSecurityTrustHtml(solar_sortByTimeOutline),
        );
        this.iconRegistry.addSvgIconLiteral(
            'solar_alignLeftOutline',
            this.sanitizer.bypassSecurityTrustHtml(solar_alignLeftOutline),
        );
        this.iconRegistry.addSvgIconLiteral(
            'solar_plateOutline',
            this.sanitizer.bypassSecurityTrustHtml(solar_plateOutline),
        );
        this.iconRegistry.addSvgIconLiteral(
            'solar_layersMinimalisticOutline',
            this.sanitizer.bypassSecurityTrustHtml(solar_layersMinimalisticOutline),
        );
        this.iconRegistry.addSvgIconLiteral(
            'solar_linkMinimalistic2Outline',
            this.sanitizer.bypassSecurityTrustHtml(solar_linkMinimalistic2Outline),
        );
        this.iconRegistry.addSvgIconLiteral(
            'solar_widgetAddOutline',
            this.sanitizer.bypassSecurityTrustHtml(solar_widgetAddOutline),
        );
        this.iconRegistry.addSvgIconLiteral(
            'solar_boardOutline',
            this.sanitizer.bypassSecurityTrustHtml(solar_boardOutline),
        );
        this.iconRegistry.addSvgIconLiteral(
            'solar_tableOutline',
            this.sanitizer.bypassSecurityTrustHtml(solar_tableOutline),
        );
        this.iconRegistry.addSvgIconLiteral(
            'solar_searchOutline',
            this.sanitizer.bypassSecurityTrustHtml(solar_searchOutline),
        );
        this.iconRegistry.addSvgIconLiteral(
            'solar_smartphoneOutline',
            this.sanitizer.bypassSecurityTrustHtml(solar_smartphoneOutline),
        );
        this.iconRegistry.addSvgIconLiteral(
            'solar_refreshOutline',
            this.sanitizer.bypassSecurityTrustHtml(solar_refreshOutline),
        );
        this.iconRegistry.addSvgIconLiteral(
            'solar_dollarOutline',
            this.sanitizer.bypassSecurityTrustHtml(solar_dollarOutline),
        );
        this.iconRegistry.addSvgIconLiteral(
            'solar_globalOutline',
            this.sanitizer.bypassSecurityTrustHtml(solar_globalOutline),
        );
        this.iconRegistry.addSvgIconLiteral(
            'solar_hashtagSquareOutline',
            this.sanitizer.bypassSecurityTrustHtml(solar_hashtagSquareOutline),
        );
        this.iconRegistry.addSvgIconLiteral(
            'solar_listMinimalisticOutline',
            this.sanitizer.bypassSecurityTrustHtml(solar_listMinimalisticOutline),
        );
        this.iconRegistry.addSvgIconLiteral(
            'solar_fileTextOutline',
            this.sanitizer.bypassSecurityTrustHtml(solar_fileTextOutline),
        );
        this.iconRegistry.addSvgIconLiteral(
            'solar_checklistMinimalisticOutline',
            this.sanitizer.bypassSecurityTrustHtml(solar_checklistMinimalisticOutline),
        );
        this.iconRegistry.addSvgIconLiteral(
            'solar_checkSquareOutline',
            this.sanitizer.bypassSecurityTrustHtml(solar_checkSquareOutline),
        );
        this.iconRegistry.addSvgIconLiteral(
            'solar_listCheckMinimalisticOutline',
            this.sanitizer.bypassSecurityTrustHtml(solar_listCheckMinimalisticOutline),
        );
        this.iconRegistry.addSvgIconLiteral(
            'solar_sortHorizontalOutline',
            this.sanitizer.bypassSecurityTrustHtml(solar_sortHorizontalOutline),
        );
        this.iconRegistry.addSvgIconLiteral(
            'solar_verifiedCheckOutline',
            this.sanitizer.bypassSecurityTrustHtml(solar_verifiedCheckOutline),
        );
        this.iconRegistry.addSvgIconLiteral(
            'solar_closeSquareOutline',
            this.sanitizer.bypassSecurityTrustHtml(solar_closeSquareOutline),
        );
        this.iconRegistry.addSvgIconLiteral(
            'solar_infoCircleOutline',
            this.sanitizer.bypassSecurityTrustHtml(solar_infoCircleOutline),
        );
        this.iconRegistry.addSvgIconLiteral(
            'solar_home2Outline',
            this.sanitizer.bypassSecurityTrustHtml(solar_home2Outline),
        );
        this.iconRegistry.addSvgIconLiteral(
            'solar_altArrowRightOutline',
            this.sanitizer.bypassSecurityTrustHtml(solar_altArrowRightOutline),
        );
        this.iconRegistry.addSvgIconLiteral(
            'solar_addCircleOutline',
            this.sanitizer.bypassSecurityTrustHtml(solar_addCircleOutline),
        );
    }
}
